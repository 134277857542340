//moduleの宣言
var ModuleAccess = function ModuleAccess(){}

var currentUrl = window.location.pathname.split("/",2);

if(currentUrl[1] == "access.html") {
  google.maps.event.addDomListener(window, 'load', function() {
    var current = new google.maps.LatLng(33.211658, 129.919379);
    var between = new google.maps.LatLng(33.206658, 129.929379);
    var myOptions = {
      zoom: 13,
      disableDoubleClickZoom: false,
      center: current,
      scrollwheel: false,
      mapTypeControlOptions: { mapTypeIds: ['sample', google.maps.MapTypeId.ROADMAP] }
    };
    var map = new google.maps.Map(document.getElementById('map'), myOptions);

    var styleOptions = [{
      stylers: [{
        saturation: 0
      },{
        lightness: 0
      }]
    }];

    var styledMapOptions = { name: '房空路' }
    var sampleType = new google.maps.StyledMapType(styleOptions, styledMapOptions);
    map.mapTypes.set('sample', sampleType);
    map.setMapTypeId('sample');

    /*アイコン設定▼*/
    var icon = new google.maps.MarkerImage('images/icon/map.png',
      new google.maps.Size(80,92),
      new google.maps.Point(0,0)
      );
    var markerOptions = {
      position: current,
      map: map,
      icon: icon,
      title: '房空路'
    };
    var marker = new google.maps.Marker(markerOptions);

    //吹き出し設定
    var infoWindow = new google.maps.InfoWindow({
      content: '<div>房 空路 そばダイニング</div>'
    });
    infoWindow.open(map, marker);

  });
}

module.exports = ModuleAccess;

//moduleの宣言
var ModuleInfoDetail = function ModuleInfoDetail(){}

var currentUrl = window.location.pathname.split("/",2);

if(currentUrl[1] == "info.html") {
  window.addEventListener("load", function() {

    //referrerなしアクセス禁止
    var ref = document.referrer;
    if(currentUrl[1] === 'info_detail' && ref === '') {
      window.location.href = "/";
    }

    window.modalOpen = function(num) {
      var modal = document.getElementsByClassName('modal' + num);
      var overlay = document.getElementsByClassName('modal_overlay');
      modal[0].classList.add('modal_show');
      overlay[0].classList.add('modal_show');
    };
    window.modalClose = function() {
      var modal = document.querySelectorAll('.modal');
      var overlay = document.getElementsByClassName('modal_overlay');
      modal.forEach( function(value, index, array) {
        modal[index].classList.remove('modal_show');
      });
      overlay[0].classList.remove('modal_show');
    };    
  }, false);
}

module.exports = ModuleInfoDetail;

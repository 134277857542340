'use strict'

import '../styles/variables.scss';
import '../styles/swiper.scss';
import '../styles/foundation.scss';
import '../styles/layout.scss';
import '../styles/form.scss';
import '../styles/component.scss';
import '../styles/project.scss';
import '../styles/utility.scss';

var General          = require('./modules/ModuleGeneral');
var Top              = require('./modules/ModuleTop');
var Access           = require('./modules/ModuleAccess');
var InfoDetail       = require('./modules/ModuleInfoDetail');
var Gallery          = require('./modules/ModuleGallery');
var GalleyDetail     = require('./modules/ModuleGalleryDetail');
//moduleの宣言
var ModuleGalleyDetail = function ModuleGalleyDetail(){}

var currentUrl = window.location.pathname.split("/",2);

if(currentUrl[1] == "gallery_detail.html") {
  window.addEventListener('DOMContentLoaded', function() {
    var price  = document.getElementById('price');
    if(price) {
      var result = String(price.textContent).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      price.textContent = result;
    };
  }, false);
  window.addEventListener('load', function() {

    //referrerなしアクセス禁止
    var ref = document.referrer;
    if(currentUrl[1] === 'gallery_detail' && ref === '') {
      window.location.href = "/";
    }

    //Swiper設定
    var mySwiper = new Swiper('.gallery_recommend', {
      loop: false,
      slidesPerView: 5,
      spaceBetween: 10,
      pagination: '.swiper-pagination',
      paginationClickable: true,
      grabCursor: true,
      breakpoints: {
        600: {
          slidesPerView: 2,
          spaceBetween: 10,
        }
      }
    });
    //swiper
    var galleryTop = new Swiper('.gallery-top', {
      // loop: true,
      spaceBetween: 10,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
    });
    var galleryThumbs = new Swiper('.gallery-thumbs', {
      freeMode: true,
      grabCursor: true,
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 'auto',
      touchRatio: 0.2,
      slideToClickedSlide: true
    });
    galleryTop.params.control = galleryThumbs;
    galleryThumbs.params.control = galleryTop;


  }, false);
}

module.exports = ModuleGalleyDetail;

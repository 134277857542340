"use strict";

var ModuleGeneral = function ModuleGeneral(){}

import riot from 'riot';
import moveto from 'moveto';

//location
var currentUrl = window.location.pathname.split("/",2);
/*UserAgent*/
var isIOS = /iP(hone|(o|a)d)/.test(navigator.userAgent);

window.addEventListener('DOMContentLoaded', function() {

  /*
  ** riot
  */
  require('../../../views/header-tpl.tag');
  require('../../../views/footer-tpl.tag');
  riot.mount('*');

  //loader
  var showLoader = function() {
    var target = document.getElementById('loader');
    target.classList.add('loader_show');
  }

  //open menu
  var openMenu = document.getElementById('openMenu');
  openMenu.addEventListener('click', function() {
    var header = document.getElementsByTagName("header");
    header[0].classList.add('open');
  }, false);
  //close menu
  var closeMenu = document.getElementById('closeMenu');
  closeMenu.addEventListener('click', function() {
    var header = document.getElementsByTagName("header");
    header[0].classList.remove('open');
  }, false);


  //scroll animation
  var easeFunctions = {
    easeInQuad: function (t, b, c, d) {
      t /= d;
      return c * t * t + b;
    },
    easeOutQuad: function (t, b, c, d) {
      t /= d;
      return -c * t* (t - 2) + b;
    }
  }
  var moveTo = new MoveTo({
    ease: 'easeInQuad'
  }, easeFunctions);
  var triggers = document.getElementsByClassName('js-trigger');
  for (var i = 0; i < triggers.length; i++) {
    moveTo.registerTrigger(triggers[i]);
  }

  //ripple effect ----------------------------------------------------------------
  (function() {
    var ripple, ripples, RippleEffect,loc, cover, coversize, style, x, y, i, num, pos;
    
    //クラス名rippleの要素を取得
    ripples = document.querySelectorAll('.ripple');

    //位置を取得
    RippleEffect = function(e) {
    ripple = this;//クリックされたボタンを取得
    cover = document.createElement('span');//span作る
    coversize = ripple.offsetWidth;//要素の幅を取得
    loc = ripple.getBoundingClientRect();//絶対座標の取得
    x = e.pageX - loc.left - window.pageXOffset - (coversize / 2);
    y = e.pageY - loc.top - window.pageYOffset - (coversize / 2);
    pos = 'top:' + y + 'px; left:' + x + 'px; height:' + coversize + 'px; width:' + coversize + 'px;';

    //spanを追加
    ripple.appendChild(cover);
    cover.setAttribute('style', pos);
    cover.setAttribute('class', 'rp-effect');//クラス名追加
    
    //しばらくしたらspanを削除
    setTimeout(function() {
      var list = document.getElementsByClassName( "rp-effect" ) ;
      for(var i =list.length-1;i>=0; i--){//末尾から順にすべて削除
        list[i].parentNode.removeChild(list[i]);
    }}, 2000)};
    for (i = 0, num = ripples.length; i < num; i++) {
      ripple = ripples[i];
      ripple.addEventListener('mousedown', RippleEffect);
    }
  }());

  //cookieを取出します。
  var cookieItem = document.cookie.split(";");
  var cookieValue = "";

  for (var i=0; i<cookieItem.length; i++) {
    var elem = cookieItem[i].split("=");
    if (elem[0].trim() == "first_loader") {
      cookieValue = unescape(elem[1]);
    } else {
      continue;
    }
  }
  //初期ページアニメーション
  if(cookieValue != 'true') {
    var target = document.getElementById('firstImageLoader');
    target.style.display = "block";
    var img1 = document.createElement('img');
    var img2 = document.createElement('img');
    var img3 = document.createElement('img');

    img1.src = "images/top/logo1.png";
    img2.src = "images/top/logo2.png";
    img3.src = "images/top/logo3.png";

    setTimeout(function() {
      target.insertBefore(img1, null);
    }, 500);
    setTimeout(function() {
      target.insertBefore(img2, null);
    }, 1000);
    setTimeout(function() {
      target.insertBefore(img3, null);
    }, 1500);
    setTimeout(function() {
      target.classList.add('out');
    }, 3000);
  };
  //cookie
  var date = 60 * 60 * 24;
  document.cookie = 'first_loader=true; max-age=' + date;

});

//ある一定以上スクロールしたらheaderをだします。PCのみ
window.addEventListener('scroll', function() {
  var ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  var wh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  if(ww > 768) {
    var y = window.pageYOffset;
    var header = document.getElementsByTagName('header');
    var hh = header[0].clientHeight;
    if(currentUrl[1] === "") {
      header[0].style.display = "none";
      if(y > wh - 65) {
        header[0].style.display = "block";
        header[0].classList.add('anime1');
        setTimeout(function() {
          header[0].classList.add('anime2');
        }, 200);
      } else {
        header[0].classList.remove('anime1');
        header[0].classList.remove('anime2');
      }
    } else {
      if(y > hh) {
        header[0].classList.add('anime1');
        setTimeout(function() {
          header[0].classList.add('anime2');
        }, 200);
      } else {
        header[0].classList.remove('anime1');
        header[0].classList.remove('anime2');
      }
    }
  }
});

export default ModuleGeneral;

//moduleの宣言
var ModuleTop = function ModuleTop(){}

var currentUrl = window.location.pathname.split("/",2);

import Swiper from 'swiper/dist/js/swiper.min.js';

if(currentUrl[1] == "") {
  window.addEventListener("load", function() {
    var leftSwiper = new Swiper('.index_left', {
      autoplay:true,
      effect: 'fade',
      speed: 8000,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
    });
    var rightSwiper = new Swiper('.index_right', {
      autoplay:true,
      effect: 'fade',
      speed: 8000,
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
    });
    //swiper resize対策
    window.addEventListener('resize', function() {
      var leftprm = leftSwiper.params;
      var rightprm = rightSwiper.params;
      leftSwiper.destroy( true, true );
      rightSwiper.destroy( true, true );
      leftSwiper = new Swiper ('.index_left', leftprm );
      rightSwiper = new Swiper ('.index_right', rightprm );
    }, false);    
  }, false);
}

export default ModuleTop;

//moduleの宣言
var ModuleGallery = function ModuleGallery(){}

var currentUrl = window.location.pathname.split("/",2);

if(currentUrl[1] == "gallery.html") {
  window.addEventListener('load', function() {
    //ajax 通信時 loader
    var async_loader = function(val) {
      var target = document.getElementById('async_loader');
      if(val === 1) {
        target.style.display = "block";
      } else if(val === 0) {
        target.style.display = "none";
      };
    };

    //写真を下からふわっとさせます。
    var card = document.getElementById('card_group');
    card.classList.add('rise');

    //galleryでjsonを取得後写真を表示。初期値。受け取りのみ
    window.gallery_list = function() {

      async_loader(1);

      var httpObj = new XMLHttpRequest();
      httpObj.open("GET", "views/client/gallery_list.json", true); //trueじゃないとtimeout動かない。trueは同期処理
      httpObj.timeout = 10000;
      httpObj.onload = function(){

        if(httpObj.readyState === 4) {
          if(httpObj.status === 200) {
            var json         = JSON.parse(this.responseText);
            var card_group   = document.getElementById('card_group');
            var select_group = document.getElementById('select_group');
            var pagination   = document.getElementById('pagination');

            //新しい要素を挿入
            for(var i=0; i<json.length; i++) {
              //flag立っていない場合無視。
              if (json[i].view_flag !== 0) {
                if(json[i].image_flag === 0) {
                  var ele = document.createElement('section');
                  ele.innerHTML = [
                    '<button type="submit" name="gallery_id" value="' + json[i].id + '" class="link_overlay">',
                      '<span class="image_cover" style="background-image:url(../images/gallery/photo/' + json[i].photo + '.jpg);"></span>',
                    '</button>',
                    '<div class="content">',
                      '<p class="title ellipsis row1">' + json[i].name + '</p>',
                      '<p class="description ellipsis row2">' + json[i].description + '</p>',
                    '</div>',
                  ].join('');
                  card_group.insertBefore(ele, null);
                } else {
                  var ele = document.createElement('section');
                  ele.innerHTML = [
                    '<div class="gallery_photo_only_section">',
                      '<button type="button" class="link_overlay" onClick="modalOpen(' + i + ')">',
                        '<span class="image_cover" style="background-image:url(../images/gallery/photo/' + json[i].photo + '.jpg);"></span>',
                      '</button>',
                      '<div class="modal modal' + i + '">',
                        '<div class="btn_close" onClick="modalClose(' + i + ')">×</div>',
                        '<div class="modal_inner">',
                          '<img src="images/gallery/photo/' + json[i].photo + '.jpg" alt="焼き物画像">',
                        '</div>',
                      '</div>',
                    '</div>',
                  ].join('');
                  card_group.insertBefore(ele, null);
                }
              }
            }
            //sectionタグが０の処理（登録数が０）この場合は全て無い状態。
            if(card_group.childNodes.length === 0) {
              var ele = document.createElement('div');
              ele.className = "no_item_display"
              ele.innerHTML = [
                '<img src="../images/info/hero.jpg">',
                '<p>ただいま焼いております。<br>詳しくは下記電話番号よりお問い合わせください。</p>',
                '<a href="telto:0954452234"><img src="images/icon/tel.svg" class="icon">0954-45-2234</a>',
              ].join('');
              card_group.insertBefore(ele, null);
            } else {
              //要素を表示
              select_group.style.display = "block";
              pagination.style.display = "block";
            };

            setTimeout( function() {
              async_loader(0);
            },500);
          }
        }
      };
      httpObj.ontimeout = function (e) {
        console.log(e)
      };
      httpObj.send(null);
    };
    gallery_list(); //即時関数だと怒られます。

    //pagination json init
    var pagination = function() {
      var ul_pager = document.getElementById('pagination');
      var httpObj = new XMLHttpRequest();
      var result = 0;
      var pager_count = 0;

      httpObj.open("GET", "views/client/pagination.json", false);  //同期に
      httpObj.onload = function(){
        var json = JSON.parse(this.responseText);
        var limit = 50;

        if(json.length > limit) {

          if (json.length % limit === 0) {
            result = json.length / limit;
          } else {
            result = json.length / limit + 1
          }
          
          //add pager button
          for(var i=1; i<=result; i++) {
            var ele = document.createElement('li');
            ele.className = "pager";
            ele.innerHTML = [
              '<button onClick="sendData({ajax_sort:document.galleryForm.sort.value, ajax_category:document.galleryForm.category.value, ajax_price:document.galleryForm.price.value, ajax_keyword:document.galleryForm.search.value, ajax_pager:' + pager_count + '})">' + [i] + '</button>',
            ].join('');
            ul_pager.insertBefore(ele, null);
            pager_count++;
          }
          var targetPager = document.getElementsByClassName('pager');
          targetPager[0].classList.add("active");
        }
      }
      httpObj.send(null);
      
    };
    pagination();

    //open modal
    window.modalOpen = function(num) {
      var modal = document.getElementsByClassName('modal' + num);
      modal[0].classList.remove('none');
      setTimeout(function() {
        modal[0].classList.remove('modal_hide');
        modal[0].classList.add('modal_show');
      },10);
    };
    //close modal
    window.modalClose = function(num) {
      var modal = document.getElementsByClassName('modal' + num);
      modal[0].classList.remove('modal_show');
      modal[0].classList.add('modal_hide');
      setTimeout(function() {
        modal[0].classList.add('none');
      },1000);
    };

    //カテゴリーセレクト欄を自動生成
    (function() {
      setTimeout(function() {
        if(document.getElementById('getCategory')) {

          var category, result, c_split;
          var d_split = [];
          
          category = document.getElementById('getCategory');
          result = category.textContent;
          result = result.slice(4, -1);
          c_split = result.split(',')
          for (var i=0; i<c_split.length; i++) {
            if (i === 0) {
              d_split.push(c_split[i]);
            } else {
              d_split.push(c_split[i].slice(1));
            }
          }

          var selectElement = document.getElementById("category");
          for(var i=0; i<d_split.length; i ++){
            var option = document.createElement("option");
            option.value = d_split[i];
            option.innerText = d_split[i];
            selectElement.appendChild(option);
          }
        }
      },1)
    }());

    //日付蘭を生成
    (function() {
      if(document.getElementById('archive')) {
        var date = new Date();
        var endYear = date.getFullYear();
        var endMonth = date.getMonth() + 1;

        if(endMonth <= 6) {
          var endSelect = endYear + "年上半期";
        } else {
          var endSelect = endYear + "年下半期";
        }
        var start = new Date("2014-1-1");
        var startYear = start.getFullYear();
        var len = endYear - startYear;
        var selectElement = document.getElementById('archive');

        for (var i=0; i<=len; i++) {
          var option = document.createElement('option');
          option.value = i;
          option.innerText = startYear + i + "年";
          selectElement.appendChild(option);
        }
      }
    })();

    //検索ボックスに入力した際の挙動
    var searchInput = document.getElementById('searchInput');
    searchInput.addEventListener('keydown', function() {
      var x32_1 = document.getElementById("_x32_1");
      var x32_2 = document.getElementById("_x32_2");
      x32_1.style.fill = "#EB6100";
      x32_1.style.transition = "1s";
      x32_2.style.fill = "#EB6100";
      x32_2.style.transition = "1s";
    }, false);
    searchInput.addEventListener('keyup', function() {
      var inputValue = document.getElementById('searchInput').value;
      var x32_1 = document.getElementById("_x32_1");
      var x32_2 = document.getElementById("_x32_2");
      if(inputValue == "") {
        x32_1.style.fill = "rgb(75, 75, 75)";
        x32_1.style.transition = "1s";
        x32_2.style.fill = "rgb(75, 75, 75)";
        x32_2.style.transition = "1s";
      }
    }, false);

    //Galleryページ各項目での抽出。セレクト後
    window.sendData = function(data) {
      var XHR = new XMLHttpRequest();
      var urlEncodedData = "";
      var urlEncodedDataPairs = [];
      var name;
      var targetURL = location.protocol + '//' + location.host + '/gallery';

      for(name in data) {
        urlEncodedDataPairs.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
      }
      urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

      XHR.open('POST', targetURL, true);
      XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

      //return
      XHR.onload = function(event) {
        if(XHR.readyState === 4) {
          if(XHR.status === 200) {
            // console.log(XHR.statusText, "success");
           
            //現在ある子要素を削除する。
            var target = document.getElementById('card_group');
            target.classList.remove('rise');
            while (target.firstChild) target.removeChild(target.firstChild);

            //jsonを取得
            var httpObj = new XMLHttpRequest();
            httpObj.open("GET", "views/client/gallery_list.json", false);
            httpObj.onload = function(){

              var json = JSON.parse(this.responseText);
              var card_group   = document.getElementById('card_group')
              var select_group = document.getElementById('select_group')
              
              //新しい要素を挿入
              for(var i=0; i<json.length; i++) {
                //flag立っていない場合無視。
                if (json[i].view_flag !== 0) {
                  var ele = document.createElement('section');
                  ele.innerHTML = [
                    '<button type="submit" name="gallery_id" value="' + json[i].id + '" class="link_overlay">',
                      '<span class="image_cover" style="background-image:url(../images/gallery/photo/' + json[i].photo + '.jpg);"></span>',
                    '</button>',
                    '<div class="content">',
                      '<p class="title ellipsis row1">' + json[i].name + '</p>',
                      '<p class="description ellipsis row2">' + json[i].description + '</p>',
                    '</div>',
                  ].join('');
                  document.getElementById('card_group').insertBefore(ele, null);
                }
              }
              //sectionタグが０の処理（登録数が０）
              if(card_group.childNodes.length === 0) {
                var ele = document.createElement('div');
                ele.className = "no_item_display";
                ele.innerHTML = [
                  '<img src="../images/info/hero.jpg">',
                  '<p>ただいま焼いております。<br>詳しくは下記電話番号よりお問い合わせください。</p>',
                  '<a href="telto:0954452234"><img src="images/icon/tel.svg" class="icon">0954-45-2234</a>',
                ].join('');
                card_group.insertBefore(ele, null);
              }
            }
            httpObj.send(null);

            //jsonを取得 pagination
            var ul_pager = document.getElementById('pagination');
            var pager_result = 0;
            var pager_count = 0;
            var httpObj02 = new XMLHttpRequest();
            httpObj02.open("GET", "views/client/pagination.json", false); //current表記のため同期処理へ
            httpObj02.onload = function(){
              
              var json02 = JSON.parse(this.responseText);
              //内部初期化。
              while (ul_pager.firstChild) ul_pager.removeChild(ul_pager.firstChild);

              // console.log(json02.length);

              var limit = 50;
              if(json02.length > limit) {
                if (json02.length % limit === 0) {
                  pager_result = Math.floor(json02.length / limit);
                } else {
                  pager_result = Math.floor(json02.length / limit + 1);
                }

                //add pager button
                for(var i=1; i<=pager_result; i++) {
                  var ele = document.createElement('li');
                  ele.className = "pager";
                  ele.innerHTML = [
                    '<button onClick="sendData({ajax_sort:document.galleryForm.sort.value, ajax_category:document.galleryForm.category.value, ajax_price:document.galleryForm.price.value, ajax_keyword:document.galleryForm.search.value, ajax_pager:' + pager_count + '})">' + [i] + '</button>',
                  ].join('');
                  ul_pager.insertBefore(ele, null);
                  pager_count ++;
                }
              }
            }
            httpObj02.send(null);

            //json取得03 pagerにcurrent
            var httpObj03 = new XMLHttpRequest();
            httpObj03.open("GET", "views/client/current_page.json", false);
            httpObj03.onload = function(){
              var json03 = JSON.parse(this.responseText);
              var targetPager = document.getElementsByClassName('pager');
              if(targetPager.length !== 0) {
                targetPager[json03].classList.add("active");
              }
            }
            httpObj03.send(null);

            setTimeout(function() {
              target.classList.add('rise'); //クラスを付与
            },10);

          } else {
            console.log(XHR.statusText);
          }
        }
      }
      // onError
      XHR.addEventListener('error', function(event) {
        console.log(event.type)
      });
      XHR.send(urlEncodedData);
    };

    //リロードボタンを押した際の挙動。
    window.init_search = function() {
      document.galleryForm.sort.value = "0";
      document.galleryForm.category.value = "";
      document.galleryForm.price.value = "0,100000";
      document.galleryForm.search.value = "";
      //input
      var inputValue = document.getElementById('searchInput').value;
      var x32_1 = document.getElementById("_x32_1");
      var x32_2 = document.getElementById("_x32_2");
      if(inputValue == "") {
        x32_1.style.fill = "rgb(75, 75, 75)";
        x32_1.style.transition = "1s";
        x32_2.style.fill = "rgb(75, 75, 75)";
        x32_2.style.transition = "1s";
      }
    }
    
  },false);
}


//宣言
module.exports = ModuleGallery;

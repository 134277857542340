
    var riot = require('riot')
    riot.tag2('header-tpl', '<header class="header"> <h1><a href="/"><img src="images/top/logo_text.png"></a></h1> <div id="closeMenu" class="close_btn"><div class="inner"></div></div> <nav> <ul> <li class="n_top"><a href="/index.html"><span>トップページ</span></a></li> <li class="n_info"><a href="/info.html"><span>お知らせ</span></a></li> <li class="n_menu"><a href="/menu.html"><span>お品書き</span></a></li> <li class="n_gallery"><a href="https://boukuuji.base.shop/" target="_blank"><span>焼き物</span></a></li> <li class="n_soba"><a href="/soba.html"><span>こだわりのそば</span></a></li> <li class="n_about"><a href="/about.html"><span>房 空路について</span></a></li> <li class="n_access"><a href="/access.html"><span>アクセスマップ</span></a></li> </ul> </nav> </header> <div id="openMenu" class="open_btn"><div class="inner"></div></div> <div id="loader" class="loader"> <div class="loader_inner"> <div class="bounce_ball"> <div class="ball"></div> <div class="shadow"></div> </div> </div> </div> <div class="first_loader" id="firstImageLoader"></div>', '', '', function(opts) {
    var checkUrl = window.location.href.indexOf('localhost') != -1;
    this.menuOpen = function() {
      if(checkUrl) {
        $('.slidemenu').toggleClass("open");
        $('.slidemenu_backdrop').toggleClass('show');
        $('.slidemenu_toggle').toggleClass('open');
      }
    }.bind(this)
    this.menuClose = function() {
      if(checkUrl) {
        $('.slidemenu').removeClass("open");
        $('.slidemenu_backdrop').removeClass('show');
        $('.slidemenu_toggle').removeClass('open');
      }
    }.bind(this)
});
    
  